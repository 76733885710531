<script setup>
import LocationLayout from "@/Layouts/LocationLayout.vue";
import { computed, inject, ref } from "vue";
import Button from "@/Components/Button/Button.vue";
import ButtonLink from "@/Components/Button/Link.vue";
import Title from "@/Components/Title/Title.vue";
import Shift from "../Planning/Partials/Shift.vue";
import RetractShiftForm from "./Partials/RetractShiftForm.vue";
import SplitShiftForm from "./Partials/SplitShiftForm.vue";

const route = inject("route");

const props = defineProps({
  shift: Object,
  multiShifts: Array,
  shiftDetails: Object,
  liked_flexer_ids: Array,
  createdBy: String
});

const dayjs = inject("dayjs");
const shiftDate = dayjs(props.shift.start).format("DD MMMM YYYY");

let subtitleLines = [];

props.multiShifts.forEach(shift => {
  const startDate = dayjs(shift.start).format("YYYY-MM-DD");
  const endDate = dayjs(shift.end).format("YYYY-MM-DD");

  const startTime = dayjs(shift.start).format("HH:mm");
  const endTime = dayjs(shift.end).format("HH:mm");

  const breakMinutes = shift.break / 60;

  const subtitleLine = `${startDate} To ${endDate} | ${startTime}-${endTime} | ${breakMinutes} min\n<br/>`;

  subtitleLines.push(subtitleLine);
});

const subTitle = subtitleLines.join("\n");

const createdBy = computed(() => {
  return props.createdBy ?? "";
});

const showSplitShiftForm = ref(false);
const showRetractShiftForm = ref(false);

const hasFilledChildren = computed(() =>
  props.shift.child_shifts.some(shift => shift.status === "filled")
);
const isInNext96Hours = computed(() =>
  dayjs(props.shift.start)
    .startOf("day")
    .subtract(4, "days")
    .isBefore(dayjs())
);
const allowedToEdit = computed(
  () => !isInNext96Hours.value || props.shift.status === "open"
);
const allowedToRetract = computed(
  () => !isInNext96Hours.value || props.shift.status === "open"
);
</script>

<template>
  <LocationLayout :title="$t('Shifts')">
    <Title :title="shift.position_name" :sub-title="subTitle">
      <template v-if="hasFilledChildren">
        <Button
          orange
          @click="showSplitShiftForm = true"
        >{{ $t('Change {model}', { model: $t('shift') }) }}</Button>
      </template>
      <template v-else>
        <Button
          danger
          :disabled="!allowedToRetract"
          @click="showRetractShiftForm = true"
        >{{ $t('Retract {model}', { model: $t('shift') }) }}</Button>

        <ButtonLink
          :disabled="!allowedToEdit"
          orange
          :href="route('location.shifts.edit', { id: shift.id })"
        >{{ $t('Change {model}', { model: $t('shift') }) }}</ButtonLink>
      </template>
    </Title>

    <div class="flex gap-8">
      <div class="w-1/4">
        <div v-if="createdBy" class="mb-6 text-blue">
          <h3 class="mb-2 text-base font-bold">{{ $t('Created by') }}</h3>
          <p>{{ createdBy }}</p>
        </div>
        <div v-if="shift.description" class="mb-6 text-blue">
          <h3 class="mb-2 text-base font-bold">{{ $t('About the shift') }}</h3>
          <p>{{ shift.description }}</p>
        </div>

        <div v-for="(details, title) in shiftDetails" :key="title" class="mb-6 text-blue">
          <h3 class="mb-2 text-base font-bold">{{ title }}</h3>
          <p v-html="details"></p>
        </div>
      </div>
      <div class="w-3/4">
        <h2 class="mb-4 text-2xl font-bold text-blue">{{ shiftDate }}</h2>
        <div class="grid">
          <Shift :shift="shift" :dropdownOpen="true" :liked_flexer_ids="liked_flexer_ids" />
        </div>
      </div>
    </div>
  </LocationLayout>
  <SplitShiftForm v-model:showing="showSplitShiftForm" :shift="shift" />
  <RetractShiftForm v-model:showing="showRetractShiftForm" :shift="shift" />
</template>
